import "../styles/Hero.css";
import mySvg from "../assets/jmg_logo.svg";
import Menu from "./Menu";

function Hero() {
  return (
    <section className="heroSection w-screen h-screen relative">
      <img className="p-4 md:hidden" src={mySvg} alt="Joe Media Group" width={136} height={136} />
      <div className="heroCover md:h-screen"></div>
      <div className="absolute left-0 md:top-0 bottom-0 md:bottom-[unset]">
        <img className="p-4 hidden md:block" src={mySvg} alt="Joe Media Group" width={136} height={136} />
        <Menu />
      </div>
      <div className="hidden md:block absolute left-0 bottom-0 p-4 w-full max-w-[400px] mb-2">
        <p className="font-clearface border-b-[1px] pb-2">© Joe Media Group</p>
      </div>
      <div className="hidden md:block absolute right-0 bottom-0 pt-2 pb-1.5 px-4 mb-4 bg-black mr-4 rounded-3xl">
        <p className="tracking-wider font-normal font-clearface">Our new home is under construction</p>
      </div>
    </section>
  );
}
export default Hero;
