import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import "../styles/Menu.css";

function Icon({ id, open }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform text-white`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default function Menu() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <section className="menuSection p-4 md:max-w-[400px] bg-black md:bg-transparent mt-8">
      <div className="md:hidden absolute left-0 mt-[-70px] ml-[15px] pt-2 pb-1.5 px-4 mb-4 bg-black mr-4 rounded-3xl">
        <p className="tracking-wider font-normal text-small font-clearface">Our new home is under construction</p>
      </div>
      <Fragment>
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(1)}>
            <h3>About</h3>
          </AccordionHeader>
          <AccordionBody>
            <p>JOE Media Group is one of the UK and Ireland's leading social publishers. Boasting brands such as JOE, SportsJOE, Her, Lovin, The London Economic and Gay Star News - our content reaches a breadth of young people across both markets.</p>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(2)}>
            <h3>Contact</h3>
          </AccordionHeader>
          <AccordionBody>
            <div>
              <p className="font-bold">UK</p>
              <p>1st Floor</p>
              <p>Exmouth House</p>
              <p>Exmouth Market</p>
              <p>London</p>
              <p className="mb-4">EC1R 0JH</p>
              <p className="font-bold">Ireland</p>
              <p>The Lofts</p>
              <p>41a Pleasant St</p>
              <p>Saint Kevin’s</p>
              <p>Dublin 8</p>
              <p className="mb-4">D08 KR99</p>
              <p className="mb-1">
                <a title="Contact us via email" href="mailto:enquiries@joemedia.group">
                  enquiries@joemedia.group
                </a>
              </p>
              <p className="mb-2">
                <a className="underline underline-offset-[3px]" href="https://www.linkedin.com/company/joe-media/" target="_blank" rel="noreferrer" title="Joe Media Group Linkedin Page">
                  LinkedIn
                </a>
              </p>
            </div>
          </AccordionBody>
        </Accordion>
      </Fragment>
      <div className="border-b-[1px]"></div>
      <div className="md:hidden mt-4 text-right">
        <p className="font-clearface">© Joe Media Group</p>
      </div>
    </section>
  );
}
